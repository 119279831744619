<template>
  <div>

    <b-card-code>
      <validation-observer ref="simple">

        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(updateVendor)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col
                md="3"
                xl="3"
              >

                <label> التاريخ</label>
              
                <b-form-input
                      id="input-brand_name"
                      v-model="Form.date"
                      type="text"
                      disabled
                    />
              </b-col>

              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="code"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> الكود</label>
               
                    <b-form-input
                      id="input-code"
                      v-model="Form.code"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="العملة"
                  label-for="currency_id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="currency_id"
                    rules="email"
                  >
                    <b-form-input
                      id="currency_id"
                      v-model="Form.currency_id.name"
                      type="currency_id"
                      disabled
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
              <b-row v-for="item in Form.entries">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="الدائن"
                  label-for="credit"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="credit"
                    rules="required"
                  >
                    <b-form-input
                      id="credit"
                      v-model="item.credit"
                      type="text"
                      disabled
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="brand"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>  المدين</label>
                    <b-form-input
                      id="input-brand_name"
                      v-model="item.debit"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
          
          </b-form>
        </validation-observer>

      </validation-observer>
    </b-card-code>

    <b-col
      md="4"
      xl="2"
    >
      <b-button
        variant="purple "
        @click="$router.go(-1)"
      >
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,

  BButton,
  BForm,
  BFormInvalidFeedback,
} from 'bootstrap-vue'


export default {

  components: {
    BFormInvalidFeedback,

    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,

    BFormGroup,
    BRow,

    BCol,
  },

  data() {
    return {
      name: '',
      number: '',
      donor_id: '',
      optionsDonor: [],
      Form: {
        name: '',
        phone: '',
        email: '',
        location: '',
        notes: '',
      },
      en_name: '',
      item_category_id: '',

      searchTerm: '',
      id: '',

    }
  },

  created() {
    this.getVendor()
  },

  methods: {

    getVendor() {
      this.$http.get(`/api/v1/finance/transactions/${this.$route.params.id}`).then(res => {
        //console.log('/finance/transactions', res.data.data)
        this.Form = res.data.data
      })
    },

    //
  },
}
</script>

  <style scoped>
  label {
    color: rgba(112, 192, 203, 255);
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .btn-purple {
    color: white;
    background-color: #7e7a9f;
    margin-top: 25px;
  }

  span {
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .align-left {
    left: 50px;
  }
  .but {
    top: 40px;
  }
  </style>
